import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full my-32">
      <div className="max-w-[1240px] mx-auto">
        <div className="text-center">
          <div>
            <h2 className="text-5xl md:text-6xl pt-2 pb-8  uppercase text-center">
              About Us
            </h2>
            <h2 className="text-2xl px-12 md:text-5xl font-bold">
              Trusted by clients in the Bay Area
            </h2>
            <p className="text-2xl md:text-3xl py-6 text-gray-500 text-left px-10">
              Special Tee Plumbing is a plumbing company that offers a range of
              services, including the installation, repair, and maintenance of
              plumbing systems. We are known for our commitment to providing
              high-quality services and excellent customer care.
            </p>
            <p className="text-2xl md:text-3xl py-6 text-gray-500 text-left pt-2 px-10">
              Special Tee Plumbing's team of trained and licensed plumbers are
              skilled in a variety of plumbing tasks, and are equipped to handle
              a wide range of plumbing issues, from simple repairs to complex
              installations. Special Tee Plumbing also offers emergency plumbing
              services, which are available 24 hours a day to help customers
              with urgent plumbing needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
