import React from "react";
import "./Header.css";
import { Link, animateScroll as scroll } from "react-scroll";
// import { Link } from "react-router-dom";

function Header() {
  function hideMenu() {
    let menuOpen = document.querySelector(".menu-toggle").checked;

    if ((menuOpen = true)) {
      document.querySelector(".toggler").checked = false;
    }
  }
  return (
    <div className="header">
      <img
        className="header__logo"
        src={require("./images/logo-plumbing.png")}
        alt="logo"
      />
      <div className="logo__text">
        <div className="logo__text">SPECIAL TEE PLUMBING</div>
        <div className="logo_small">100% Satisfaction Guarantee</div>
      </div>
      <div className="group px-2">
        <div className="header__option">
          <Link to="services" smooth={true} duration={500}>
            Services
          </Link>
        </div>
        <div className="header__option">
          {" "}
          <Link to="about" smooth={true} duration={500}>
            About Us
          </Link>
        </div>
        <div className="header__option">
          {" "}
          <Link to="gallery" smooth={true} duration={500}>
            Gallery
          </Link>
        </div>
        <div className="header__option">
          {" "}
          <Link to="contact" smooth={true} duration={500}>
            Book Now!
          </Link>
        </div>
      </div>

      <input id="menu-toggle" type="checkbox" />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu burger">
        <li>
          <Link
            to="services"
            smooth={true}
            duration={500}
            className="burger-link"
            // onClick={hideMenu}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="about"
            smooth={true}
            duration={500}
            className="burger-link onClick={hideMenu}"
            // onClick={hideMenu}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="gallery"
            smooth={true}
            duration={500}
            className="burger-link"
            // onClick={hideMenu}
          >
            Gallery
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="burger-link"
            // onClick={hideMenu}
          >
            Book Now!
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Header;
