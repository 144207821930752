import React from "react";

const Hero = () => {
  return (
    <div
      name="services"
      className="w-full h-screen bg-[url('/public/plumbing-pic.png')] bg-cover flex flex-col justify-between"
    >
      <div
        className="absolute flex flex-col py-8 md:min-w-[760px]   bottom-[0%]  md:bottom-[22%] 
            mx-1 md:left-1/2 transform md:-translate-x-1/2 bg-zinc-100/80
            border border-slate-300 rounded-2xl text-center shadow-x"
      >
        <p className="text-xl md:text-2xl py-2 underline">
          24/7 Plumbing Services in Bay Area
        </p>
        <div className="flex justify-between flex-wrap px-2 text-center">
          <p className="flex-col justify- w-1/2 md:w-1/4 py-2 text-black">
            Plumbing Repair
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Plumbing Services
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Water Heater
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Toilets
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Drains
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Sewers
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Urinal
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Water Lines
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Inspections
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Hydro Jetting
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Faucets
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Tubs
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Garbage Disposal
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Pumps
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Septic System
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Gas Lines
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Trenchless Services
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Laundry
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Water Dispenser
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Grease Trap
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Leaks
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Sink
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Shower
          </p>
          <p className="flex-col justify-center w-1/2 md:w-1/4 py-2 text-black">
            Dishwasher
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
