import React from "react";

function Gallery() {
  return (
    <div name="gallery">
      <h2 className="text-5xl md:text-6xl pt-2 pb-8  uppercase text-center">
        Gallery
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black">
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img className="h-100" src={require("./images/pic7.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic6.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img
              className="h-100 flex-col justify-center"
              src={require("./images/pic2.png")}
              alt="/"
            />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic5.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic8.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic9.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic10.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic11.png")} alt="/" />
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl">
          <div className="p-8">
            <img src={require("./images/pic12.png")} alt="/" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
